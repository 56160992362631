body, html {
  min-width: 1300px;
  background-color: #e9ebec;
}
/* 1px 下边线*/
.border-bottom {
  border-bottom: 1px solid #fdad00;
}
.content {
  background: none repeat scroll 0 0 #e9ebec;
  width: auto;
  padding: 20px 15px;
  box-sizing: border-box;
}
.content .w80 {width: 80px;}
.member-search-title {
  height: 39px;
  line-height: 39px;
  padding-left: 40px;
  background: url(../img/icon_search.png) no-repeat 15px center;
}
.member-search-text {
  padding-left: 8px;
  float: left;
}
.card-container .ivu-card-head,
.new-member-info .ivu-card-head {
  padding: 7px 16px!important;
}
.card-container .ivu-card-head {
  background: #333543;
}
.new-member-info .ivu-card-head {
  background: #f3f3f3;
  border-bottom: 1px solid #fdad00;
}
.icon-home {
  background: url(../img/icon_home.png) no-repeat center;
  padding-right: 28px;
  height: 37px;
  float: left;
}
.top-header .ivu-breadcrumb > span:last-child {
  color: #424e67;
}

.main-container .main-title h2 {
  padding-left: 45px;
  height: 32px;
  line-height: 32px;
  background: url(../img/icon_info.png) no-repeat;
  color: #424e67;
}

.member-oprate a, .stander {
  color: #333;
  padding-left: 25px;
  padding-bottom: 5px;
}
.stander {
  display: block;
  text-align: right;
}
.member-oprate a:hover {
  color: #ff9900;
}
.new-doc, .del-doc {
  padding-right: 20px;
  background-repeat: no-repeat;
}
.new-doc {
  background-image: url(../img/icon_add.png);
}
.del-doc {
  background-image: url(../img/icon_delete.png);
}
.page-nations-wrapper {
  margin: 15px auto;
  text-align: center;
}
.page-nations-wrapper .page-nations {
  display: inline-block;
}

.table-oprate {
  padding-top: 15px;
  padding-left: 18px;
}
.table-oprate .ivu-btn{
  line-height: 1;
}
.records {
  color: #999;
  float: right;
  padding-top: 15px;
}
.add-box {
  padding: 10px 0 15px 0;
}
.table-box {
  margin: 0 20px;
}
.member-detail-menu .active {
  background: #fdad00;
  color: #fff;
  border: none;
  height: 42px;
}

/************ 表格中查看&&删除 ****************/
.icon_eye, .icon_del, .icon_edit {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  text-indent: -99999em;
  vertical-align: middle;
}
.icon_eye {
  background-image: url(../img/icon_eye.png);
  background-position: center;
  margin-right: 18px;
}
.icon_del {
  background-image: url(../img/icon_del.png);
}
.ivu-icon-md-eye,
.ivu-icon-md-arrow-round-down {
  vertical-align: middle!important;
}
.icon_edit {
  background-image: url(../img/icon_s_edit.png);
  margin-right: 18px;
  background-position: 0 2px;
}
.table-text {
  color: #ff8400;
  padding-right: 15px;
}
.table-text:hover {
  color: #ff8400;
}
.photo {
  width: 30px;
  height: 30px;
  border: 1px solid #eee;
  border-radius: 50%;
}

.select-contain {
  margin-right: 15px;
  text-align: left;
}
/****************** Modal样式 *************************/
.ivu-modal-content {
  border-radius: 0;
}
.ivu-modal-content .ivu-modal-body {
  font-size: 14px;
  line-height: 1;
}
.ivu-modal-header {
  background: #51c78a;
}
.ivu-modal-header-inner, .ivu-modal-close .ivu-icon-ios-close-empty {
  color: #fff!important;
}

.user-edit-contain {
  padding: 24px 60px 0 70px;
}

/********************** 背景色 **********************/
.bg1 {background-color: #41cac0;}
.bg2 {background-color: #a9d96c;}
.bg3 {background-color: #57c8f2;}
.bg4 {background-color: #f8d347;}
.bg5 {background-color: #ff6c60;}
.bg6 {background-color: #6ccac9;}
.bg7 {background-color: #f1ce7e;}
.bg8 {background-color: #f18985;}
.bg9 {background-color: #66b0ea;}
.bg10 {background-color: #fab2c3;}
.bg11 {background-color: #94e1f0;}

.color7 {color: #f1ce7e;}
.color8 {color: #f18985;}
.color9 {color: #66b0ea;}
.color10 {color: #fab2c3;}
.color11 {color: #94e1f0;}
.color2 {color: #a9d96c;}

.scheme-contain {
  background-color: #fff;
  padding-top: 20px;
}
.symbol-box .ivu-btn-primary {
  background: #5886e6;
  border-color: #5886e6;
  margin-right: 35px;
}
.symbol-box .ivu-btn-primary:hover {
  background: #638ee9;
  border-color: #638ee9;
}
.symbol-box .ivu-btn-large {
  padding: 0 48px;
}
.scheme-contain .ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #fdad00;
}
.element-contain {
  margin-top: 40px;
}
.scheme .ivu-tabs-nav-scroll {
  overflow-x: auto;
}
/**************** 监测数据样式 **********************/
.data-contain {
  padding: 20px 15px;
  border: 1px solid #eee;
  margin: 0 12px;
}
.data-contain .ivu-tabs {
  top: -30px;
}

.data-contain .ivu-date-picker-rel,
.data-contain .ivu-select-single .ivu-select-selection,
.data-contain .ivu-btn-warning {
  position: relative;
  z-index: 10;
}
.card-wrapper .card-module {
  padding: 35px 25px 15px;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.card-wrapper .font_30 {
  font-size: 30px;
  padding-right: 5px;
  line-height: 1;
}
.card-wrapper .card-footer {
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  margin-bottom: 10px;
}
.card-module h2 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card-wrapper .card-footer.bg1 {
  background: #39b7ac;
}
.card-wrapper .card-footer.bg2 {
  background: #99c262;
}
.card-wrapper .card-footer.bg3 {
  background: #4db0d5;
}
.card-wrapper .card-module-nofoot {
  padding: 50px 25px;
  color: #fff;
  border-radius: 5px;
}
.card-module-nofoot h2 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card-module-nofoot p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card-wrapper .pl20 {padding-left: 20px;}
.card-wrapper .card-module-extra {
  padding: 62px 25px;
  color: #fff;
  border-radius: 5px;
  min-height: 140px;
}
.card-wrapper .card-module-extra h2 {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card-wrapper .monitor-search {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 35px;
}
.card-wrapper .monitor-date {
  width: 470px;
  text-align: center;
  display: inline-block;
}
.card-wrapper .tab-contain {
  float: right;
}
.card-wrapper .tab-contain a {
  padding: 6px;
  border: 1px solid #333543;
  color: #999;
  float: left;
  font-size: 12px;
}
.card-wrapper .tab-contain .static-button {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
}
.card-wrapper .tab-contain .chart-button {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;
}
.card-wrapper .tab-contain .active {
  background-color: #333543;
  color: #fff;
}
/**************** 疾病信息 ************************/
.illness-box .ivu-form .ivu-form-item-label {
  color: #424e67;
  padding-left: 30px;
}
.illness-box .ivu-date-picker-rel {
  top: 2px;
}
.hereditary-box .ivu-checkbox-group-item, .affixion-contain .ivu-checkbox-group-item {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.affixion-contain .ivu-checkbox-group-item {
  margin-bottom: 10px;
}
.sickness-contain .ivu-tabs-nav-scroll {
  text-align: center;
}
.sickness-contain .ivu-tabs-nav {
  display: inline-block;
  float: none;
}
/**********************条件搜素区块******************************/
.search-box {
  background-color: #fff;
  margin-bottom: 18px;
}
.search-box .search-title {
  height: 39px;
  line-height: 39px;
  padding-left: 45px;
  background: #fbfbfb url(../img/icon_search.png) no-repeat 15px center;
}
.search-box .search-form {
  padding: 20px 15px;
}
.search-box .search-form .ivu-form-item {
  margin-bottom: 18px;
}
.search-box .search-form .ivu-form-item .ivu-form-item {
  margin-bottom: 0;
}
.search-box .search-form .search-btn {
  font-size: 14px;
  line-height: 1;
  padding: 6px 24px;
}

/********************** 膳食记录 *************************************/
.food-date {
  background: #f1f2f7;
  padding: 10px 0;
  border: 1px solid #eee;
}
.food-date .text_in {
  height: 30px;
  line-height: 30px;
  display: block;
  font-size: 16px;
}
.food-list {
  margin: 0 15px;
  padding: 25px 55px;
  border: 1px solid #eee;
  margin-bottom: 25px;
}
.food-list .food-box {
  border: 1px solid #eee;
  margin-bottom: 35px;
}
.food-list .food-type {
  text-align: center;
  padding: 12px 0;
}
.food-list .text_in {
  display: block;
  height: 30px;
  line-height: 30px;
}
.food-list .food-enery {
  padding: 5px 12px;
  border-top: 1px solid #eee;
}
.total-enery {
  background: #fdad00;
  text-align: center;
  color: #fff;
  padding: 3px;
  margin: 25px 0;
}
.total-text {
  font-size: 25px;
}

/**********************表格样式******************************/
.ivu-table .ivu-table-header th {
  height: 38px;
  background-color: #fbfbfb;
  border-bottom-color: #fdad00;
}
.ivu-table .ivu-table-body td {
  height: 34px;
}

/**********************筛选列表公共样式******************************/
.filter-list-tpl {
  background-color: #fff;
  padding: 30px 12px;
}

.filter-list-tpl .ivu-radio-group {
  margin-bottom: 19px;
}

.filter-list-tpl .ivu-radio-wrapper {
  margin-right: 40px;
}

.filter-list-tpl .btn-allot {
  float: right;
  margin-bottom: 15px;
  color: #fff;
  border-radius: 4px;
  line-height: 1;
  padding: 6px 10px 6px 34px;
  background: #51c78a url(../img/icon_allot.png) no-repeat 10px center;
}
.filter-list-tpl .btn-allot+.btn-allot {
  margin-right: 10px;
}

/*****************方案，问题，促进共用面板*******************/
.commom-panel-one {
  padding: 25px 30px;
  background-color: #f3f3f3;
  border: 1px solid #dedede;
  margin-top: 18px;
}
.commom-panel-one .ivu-form-item {
  margin-bottom: 14px;
}
.commom-panel-one .ellipsis .ivu-form-item-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.commom-panel-two {
  background-color: #f3f3f3;
  border: 1px solid #dedede;
  margin-top: 18px;
}
.commom-panel-two .commom-panel-title {
  padding-left: 16px;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #dedede;
  font-size: 16px;
  color: #424e67;
}
.commom-panel-two .commom-panel-main {
  padding: 25px 30px;
  border-bottom: 1px solid #dedede;
}
.commom-panel-two .ivu-form-item {
  margin-bottom: 14px;
}

/************************上传方案********************/
.btn-upload {
  color: #424e67;
  padding-left: 20px;
  background: url(../img/icon_upload.png) no-repeat left center;
}
.btn-upload:hover {
  color: #333;
}
.upload-list{
  height: 34px;
  line-height: 34px;
  position: relative;
}
.upload-list-cover {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.2);
  text-align: center;
}
.upload-list:hover .upload-list-cover {
  display: block;
}
.upload-list-cover i {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
/*************** 图表宽高 ********************/
.chart {
  width: 100%;
  height: 405px;
}
.chartdetail {
  width: 100%;
  height: 150px;
}

/***********************数据统计*****************************/
.export-div {
  margin: 20px 0;
}
.export-div .export-btn {
  float: right;
  height: 20px;
  line-height: 20px;
  color: #333;
  padding-left: 26px;
  background: url(../img/icon_export.png) no-repeat left center;
}

/************************图表分析*****************************/
.chart-content {
  padding: 30px 20px;
  margin-bottom: 20px;
  background-color: #fff;
}
.chart-content .search-btn {
  font-size: 14px;
  line-height: 1;
  padding: 6px 24px;
  margin-top: 3px;
}
.mytable {
  width: 100%;
	background-color: #fff;
}
.mytable td {
	height: 38px;
	line-height: 38px;
  border: 1px solid #ddd;
}
.mytable .tdl {
  width: 370px;
  padding-left: 30px;
  background-color: #f9f9f9;
}
.mytable .tdr {
  padding-left: 36px;
  background-color: #f9f9f9;
}
.mytable .project-name {
  color: #fdad00;
}
.mytable .green-bg {
  background-color: #51c78a;
  color: #fff;
  padding-left: 0;
  text-align: center;
}
.mytable .green-word {
  color: #51c78a;
  font-size: 18px;
}
.mytable .gray-bg {
  background-color: #f1f2f7;
}
.radio-mine .ivu-radio-group-vertical .ivu-radio-wrapper {
  display: block;
  height: 36px;
  line-height: 36px;
  margin: 0;
  padding: 0 20px 0 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  border-radius: 0 18px 18px 0;
  border-left: 3px solid #d6d6d6;
}
.radio-mine .ivu-radio-group-vertical .ivu-radio-wrapper-checked {
  color: #fff;
  background-color: #66cd98;
  border-left: 3px solid #66cd98;
}
.radio-mine .ivu-radio-wrapper .ivu-radio-inner,
.radio-mine .ivu-radio-wrapper input {
  opacity: 0;
  width: 0;
  height: 0;
}
.radio-mine .ivu-radio {
  width: 0;
  margin-right: 0;
}
.mycard {
  height: 210px;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  overflow: hidden;
}
.mycard .mycard-title {
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 16px;
  color: #fff;
}

.services-text .ivu-form-label-left .ivu-form-item-label {
  color: #424e67;
}
.scheme-contain .ivu-tabs-bar {
  border: none;
  padding-left: 55px;
}
.img-avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  margin: 5px;
}

.content .ivu-input[disabled] {
  color: #999;
}
.ivu-progress {
  top: 31%;
}
.breakAll {
  word-wrap: break-word;
}
.log-title {
  line-height: 60px;
  font-size: 16px;
}
.illness-modal .ivu-select-dropdown {
  position: absolute!important;
}

.ivu-table-row .text_color {
  color: #fdad00!important;
  cursor: pointer;
}

.graph-headings {
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 4px solid #000;
  text-align: center;
}

.graph-h2 {
  font-weight: 600;
  font-size: 32px;
  margin: 0.02em;
  color: #4370c1;
}

.graph-h3 {
  font-size: 18px;
}

.divide-line {
  background-color: #fff;
  border-bottom: 2px dashed #4370c1;
  margin: 30px 0;
}

.divide-line--black {
  background-color: #fff;
  border-bottom: 1px solid #000;
  margin: 10px 0;
}

.assessment-heading {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0;
}

.assessment-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 2;
}

.assessment-title.red {
  color: #bf242a;
}

.assessment-content {
  font-size: 16px;
}

.graph-chart {
  height: 300px;
  width: 100%;
}

.data-table {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 700;
  background-color: #fff;
  border: 2px solid #000;
}

.data-table tr {
  height: 45px;
}

.data-table th,
.data-table td {
  border: 1px solid #000;
  padding: 10px 0;
}

.data-table th {
  background-color: #5a9bd5;
  color: #fff;
}

.data-table tr:nth-child(odd) {
  background-color: #eee;
}

.graph-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.plan-content-container {
  width: 920px;
  margin: 0 auto;
}

.plan-content-header_img-wrapper {
  text-align: center;
}

.plan-content-header_img {
  width: 40%;
}

.plan-content-header_title {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin: 20px 0 15px;
}

.plan-content-header_text {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  font-weight: 700;
  padding: 15px 0;
  border-bottom: #000 solid 2px;
}

.plan-content-wrapper {
  height: 1380px;
  position: relative;
}

.content-footer {
  position: absolute;
  bottom: 20px;
  font-size: 12px;
}

.vertical-center-modal{
	display: flex;
	align-items: center;
	justify-content: center;
}

.vertical-center-modal .ivu-modal{
  top: 0;
}

.inline-form-item .ivu-form-item-content {
  float: left;
}

.daily-score {
	width: 140px;
	height: 140px;
	line-height: 110px;
	text-align: center;
	color: #005ab3;
	font-size: 28px;
	border-radius: 50%;
	border: 15px solid #005ab3;
	margin: 0 30px;
  }
.daily-score .ivu-input-number {
	border: none;
	border-bottom: 1px solid #dddee1;
}

.daily-score .ivu-input-number-input {
	color: #005ab3;
	font-size: 28px;
	text-align: center;
}

.rate-card-sign .ivu-input,
.sign-wrap .ivu-input {
	width: 90px;
	border: none;
	border-bottom: 1px solid #dddee1;
	color: #005ab3;
	font-size: 16px;
	text-align: center;
}

.rate-card-sign .ivu-input-wrapper {
	right: -96px;
	top: -30px;
}

.class-page .ivu-form-item-label {
  color: #a8a8a8!important;
}

.content-title {
  height: 39px;
  line-height: 39px;
  padding: 0 10px;
  background-color: #fbfbfb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-box {
  background-color: #fff;
}

.content-body {
  margin: 10px;
}

.p-20px {
  padding: 20px;
}

.week-rate-iframe .ivu-select-item {
	white-space: normal;
}

.sugar-container .el-table thead {
	color: #fff;
}

.sugar-container .el-table th {
	background-color: #1163c1;
}

.sugar-container .el-table--border, .sugar-container .el-table--group {
	border: 1px solid #1b7cec;
}

.sugar-container .el-table__empty-text, .sugar-container .el-table td .cell {
	color: #1b7cec;
}

.sugar-container textarea.ivu-input {
	border: 1px solid #297ddf;
	color: #005AB3;
	font-size: 18px;
}

// 主要内容块
.main-container {
  .main-content-wrapper {
    padding: 15px;
  }
  .main-search {
    background-color: #fff;
    margin-bottom: 15px;
    &__title {
      height: 40px;
      line-height: 40px;
      padding: 0 15px 0 40px;
      background: url(../img/icon_search.png) no-repeat 15px center;
      display: flex;
      justify-content: space-between;
    }
    &__title-text {
      padding-left: 8px;
    }
    &__body {
      padding: 15px 15px 0;
    }
  }
  .main-content {
    &__title {
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      background-color: #fbfbfb;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__body {
      background-color: #fff;
      padding: 15px 15px 0;
    }
    + .main-content {
      margin-top: 15px;
    }
  }
}
