body, h1, h2, h3, h4, h5, h6, hr, p, blockquote,
dl, dt, dd, ul, ol, li,
pre,
fieldset, legend, button, input, textarea,
th, td {
    margin: 0;
    padding: 0;
}

body,
button, input, select, textarea {
    font: 14px/1 'Microsoft Yahei',Tahoma, Helvetica, Arial, "\5b8b\4f53", sans-serif;
    color: #333;
    outline: none;
}

h1 { font-size: 18px; }
h2 { font-size: 16px; }
h3 { font-size: 14px; }
h4, h5, h6 { font-size: 100%; }

h1, h2, h3, h4, h5, h6 {font-weight: 400;}

address, cite, dfn, em, var { font-style: normal; }
code, kbd, pre, samp, tt { font-family: "Courier New", Courier, monospace; }
small { font-size: 12px; }
s,i,em{font-style:normal;text-decoration:none;}

ul, ol { list-style: none; }

a { text-decoration: none; color: #333;}
a:hover { text-decoration: none; color: #ff9900;}

abbr[title], acronym[title] {
 border-bottom: 1px dotted;
 cursor: help;
}

q:before, q:after { content: ''; }
sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
}

legend { color: #000; }
fieldset, img { border: none; }
img {border:0;	vertical-align:middle; }
button, input, select, textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
    box-sizing: border-box;
    padding: 0;
    vertical-align: middle;
}
textarea {
    resize:none;
    overflow:auto;
    vertical-align:top;
    border: 1px solid #ccc;
}
table {
 	border-collapse: collapse;
 	border-spacing: 0;
}
hr {
    border: none;
    height: 1px;
    background-color: #ccc;
}

.fl{
	float: left !important;
}
.fr{
	float: right !important;
}
.vtop{
	vertical-align: top;
}
.vmid{
	vertical-align: middle;
}
.vbot{
	vertical-align: bottom;
}
.tac{
	text-align: center;
}
.tal{
	text-align: left;
}
.tar{
	text-align: right;
}
.show{
	display: block!important;
}
.hide{
	display: none!important;
}

.clearfix:before,.clearfix:after {
    content:"";
    display:table;
}
.clearfix:after{clear:both;}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    background-color: rgba(125, 125, 125, 0.7);
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:horizontal {
    width: 5px;
    background-color: rgba(125, 125, 125, 0.7);
    border-radius: 6px;
}

.fff{
	color: #fff;
}
.gray999{
	color: #999;
}
.blue2376{
	color: #2376BD;
}
.blue3a{
	color: #3a7de0;
}
.f12{
	font-size: 12px;
}
